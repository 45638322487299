/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BG_BUTTON,
  RESET_HORSE,
  RESET_REFECT
} from 'assets/images';
import ButtonStyled from './styled';

interface ButtonProps {
  onClickButton: () => void
  width?: number
  height?: number
  classNames?: any
  type?: string
  margingTop?: boolean
}

function ButtonResetPoint({
  onClickButton,
  width = 150,
  height = 45,
  classNames
}: ButtonProps) {

  const handleClick = () => {
    onClickButton()
  }

  return (
    <ButtonStyled className={classNames} >
      <div className={`btn-container`} onClick={handleClick} >
        <img src={BG_BUTTON} alt='' className='background-btn' width={width} height={height} />
        <img src={RESET_HORSE} alt='' className='reset_horse' />
        <img src={RESET_REFECT} alt='' className='reset_refect' />
      </div>
    </ButtonStyled>
  )
}

export default ButtonResetPoint
