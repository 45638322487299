import styled from 'styled-components'

const ButtonStyled = styled.div`
  cursor: pointer;
  column-gap: 8px;
  width: fit-content;
  .btn-container {
    position: relative;
    &:hover {
      filter: brightness(1.5);
    }
    .background-btn {
      position: relative;
    }
    .reset_horse {
      position: absolute;
      top: 0;
      left: 26px;
    }
    .reset_refect {
      position: absolute;
      width: 32px;
      top: 9px;
      right: 32px;
    }
  }

  .ant-spin-dot-item {
    background-color: #4ef076 !important;
  }
`

export default ButtonStyled
